<template>

  <a-layout id="components-layout-demo-custom-trigger">
<!-- 左边 -->
    <a-layout-sider v-model="collapsed" breakpoint="md" :collapsedWidth="0" :trigger="null" collapsible :style="{ overflow: 'auto', height: '100vh', left: 0 ,position:'fixed'}">
      <div class="logo" :style='{background:collapsed?"none":"",textAlign:collapsed?"center":""}'>
          <img src="@/static/img/1.svg">
          <h1 v-show="!collapsed">{{headTitle}}</h1>
        </div>

      <a-menu
      :defaultOpenKeys="menuDefault"
      v-model="menuDefault"
      mode="inline"
      theme="dark"
      :inline-collapsed="collapsed"
      @select="rout"
      >
        <template v-for="item in menu">
          <a-menu-item v-if="!item.children" :key="item.path" title=''>
            <a-icon :type="item.meta.icon" />
            <span>{{ item.meta.title }}</span>
          </a-menu-item>
          <sub-menu v-else :key="item.path" :menu-info="item" />
        </template>

      </a-menu>

    </a-layout-sider>
    <!-- 右边 -->
    <a-layout :style="{overflow: 'auto', marginLeft:collapsed?'0px':'200px',transition: 'all .2s'}">
      <a-affix :offsetTop="0">
        <a-layout-header style="background: #fff;box-shadow: 0 0 8px 0px; padding: 0;display: flex;justify-content: space-between;align-items: center;" >
          <a-icon
            class="trigger"
            :type="collapsed ? 'menu-unfold' : 'menu-fold'"
            @click="() => (collapsed = !collapsed)"
          />
          <div style="margin:0 2rem;">
            
            <a-dropdown>
              <a class="ant-dropdown-link" style="display:block;">
                <a-icon type="user" style="margin-right: .2rem;"/>{{token}}
              </a>
              <a-menu slot="overlay">
                <a-menu-item>
                  <a @click="modalShow=true">修改密码<updata-password :editModalVisible="modalShow" @hidemodal='hidemodal'></updata-password></a>
                </a-menu-item>
                <a-menu-item>
                  <a @click="logout">退出登录</a>
                </a-menu-item>
              </a-menu>
            </a-dropdown>
            </div>
        </a-layout-header>  
 
      </a-affix>

        <a-page-header v-if="Title!='首页'"
          style="border: 1px solid rgb(235, 237, 240)"
          :title="Title"      
        />   
      <!-- 内容区域 -->
      <a-layout-content :style="{ margin: '24px 16px', minHeight: '280px' }">    
          <router-view></router-view>
      </a-layout-content>

    </a-layout>
  </a-layout>
</template>
<script>
import { Menu } from 'ant-design-vue';
import {getCookie} from '@/static/js/cookie';
import updataPassword from '@/components/updataPassword.vue';
const SubMenu = {
  template: `
      <a-sub-menu :key="menuInfo.path" v-bind="$props" v-on="$listeners">
        <span slot="title">
          <a-icon :type="menuInfo.meta.icon" /><span>{{ menuInfo.meta.title }}</span>
        </span>
        <template v-for="item in menuInfo.children">
          <a-menu-item v-if="!item.children" :key="item.path">
            <a-icon :type="item.meta.icon" />
            <span>{{ item.meta.title }}</span>
          </a-menu-item>
          <sub-menu v-else :key="item.path" :menu-info="item" />
        </template>
      </a-sub-menu>
    `,
  name: 'SubMenu',
  // must add isSubMenu: true
  isSubMenu: true,
  props: {
    ...Menu.SubMenu.props,
    menuInfo: {
      type: Object,
      default: () => ({}),
    },
  },
};
export default {
  components: {
    'sub-menu': SubMenu,
    updataPassword,
  },
  data() {
    return {
      headTitle:process.env.VUE_APP_ProjectName,
      collapsed: false,
      menuDefault:[],//展开的菜单栏
      menu:[],//菜单栏
      Title:'',//页头
      modalShow:false,
    };
  },
  created(){
    //let that=this;
    console.log("传过来的路由数据",this.$route.params)

    this.menu=this.$store.getters.getRouters.filter(r=>{return r.name=='layout'})
    
    this.menu=this.menu[0].children

    this.Title=this.$route.meta.title
    this.selectMenu();
  },
  mounted(){
    
  },
  methods:{
    selectMenu(){
      this.menuDefault=[];
      let url=this.$route.path.split("/");
      let urlNode=''
      url.map((item,index)=>{
        
        if(index>=1){
          urlNode +="/"+item
          this.menuDefault.push(urlNode)
        }
      })
      console.log(this.menuDefault)
    },
    rout(item){//参数key,keypath暂时用不到
      if(document.body.clientWidth<=768)this.collapsed=true;
      console.log(item)
      this.$router.push(item.key)
    },
    logout(){
      let that=this;
      this.$confirm({
        title: '提示',
        content: '真的要注销登录吗？',
        onOk() {
          console.log("退出")
          that.$store.dispatch('LogOut').then(()=>{
            location.reload();
          })

        },
        onCancel() {},
      });

     
    },
    hidemodal(){
      this.modalShow=false
    }
  },
  watch:{
    $route(to){
      this.Title=to.meta.title
      this.selectMenu();
      console.log("传过来的路由数据",to.params)
  }
  },
  computed:{
    token:()=>{
      return JSON.parse(getCookie("token")).role
    }
    // ...mapState({
    //   token:state => state.user.token
    //   })
  },
};
</script>

<style lang="scss" scoped>
.ant-menu-inline-collapsed{
  width:100% !important;

}

.logo{
  background:none !important;
  display: flex;
  img{
    height:32px;
    width:32px;
  }
  h1{
    display: inline-block;
    margin: auto;
    color: #fff;
    vertical-align: middle;
    padding:0 10px;
  }
}

.ant-menu-item a{
  display:inline-block;
}
#components-layout-demo-custom-trigger .trigger {
  font-size: 18px;
  line-height: 64px;
  padding: 0 24px;
  cursor: pointer;
  transition: color 0.3s;
}

#components-layout-demo-custom-trigger .trigger:hover {
  color: #1890ff;
}

#components-layout-demo-custom-trigger .logo {
  height: 32px;
  background: rgba(255, 255, 255, 0.2);
  margin: 16px;
}
.ant-page-header{
  border: 1px solid rgb(235, 237, 240);
  background: #fff;
  margin-top: 5px;
}
</style>