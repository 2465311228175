import Vue from 'vue'
import Router from 'vue-router'

import layout from '@/components/layout'
import Login from '@/views/login/';
import store from '@/store';
import {getCookie} from '@/static/js/cookie';

Vue.use(Router)
const projectName=process.env.VUE_APP_ProjectName;
export const constantRouterMap = [ 
  {
    path: '/login', 
    component: Login 
  }, 
]
let router= new Router({
  routes: constantRouterMap,
  
});

export const asyncRouterMap = [
  {
    path: '/',
    name: 'layout',
    component: layout,
    meta:{
      title: '首页',
      icon: 'dashboard',
    },
    children:[
      {
        path: '/home',
        name: 'home',
        component: resolve => require(['@/views/home/index'],resolve),
        meta:{
          title: '首页',
          icon: 'dashboard',
        }
      },
      {
        path: '/group',
        name: 'group',
        component: resolve => require(['@/views/group/index'],resolve),
        meta:{
          title: '分组管理',
          icon: 'unordered-list',
          roles:"super",
        }
      },
      {
        path: '/user',
        name: 'user',
        component: resolve => require(['@/views/user/index'],resolve),
        meta:{
          title: '用户管理',
          icon: 'user',
          roles:"super",
        }
      },
      {
        path: '/51user',
        name: '51user',
        component: resolve => require(['@/views/51user/index'],resolve),
        meta:{
          title: '51账号管理',
          icon: 'user',
          roles:"super",
        }
      },
      {
        path: '/web',
        name: 'web',
        component: resolve => require(['@/views/web/index'],resolve),
        meta:{
          title: '网站管理',
          icon: 'dribbble',
          roles:"super",
        }
      },
      {
        path: '/order',
        name: 'order',
        component: resolve => require(['@/views/order/index'],resolve),
        meta:{
          title: '订单管理',
          icon: 'shopping-cart',
        }
      },
      {
        path: '/orderStatistics',
        name: 'orderStatistics',
        component: resolve => require(['@/views/orderStatistics/index'],resolve),
        meta:{
          title: '订单分组统计',
          icon: 'ordered-list',
        }
      },
      {
        path: '/orderEcharts',
        name: 'orderEcharts',
        component: resolve => require(['@/views/orderEcharts/index'],resolve),
        meta:{
          title: '订单图表统计',
          icon: 'line-chart',
        }
      },
      {
        path: '/logs',
        name: 'logs',
        component: resolve => require(['@/views/logs/index'],resolve),
        meta:{
          title: '日志查询',
          icon: 'line-chart',
          roles:"super",
        }
      },
      // {
      //   path: '/table',
      //   name: 'table',
      //   component: resolve => require(['@/views/table/index'],resolve),
      //   meta:{
      //     title: '表格示例',
      //     icon: 'dashboard',
      //     roles:"super",
      //   },
      //   children:[
      //     {
      //       path: '/table/table1',
      //       name: 'table1',
      //       component: resolve => require(['@/views/table/table1'],resolve),
      //       meta:{
      //         title: '查询表格',
      //         icon: 'dashboard',
      //         roles:"super",
      //       },
      //     },
      //     {
      //       path: '/table/table2',
      //       name: 'table2',
      //       component: resolve => require(['@/views/table/table2'],resolve),
      //       meta:{
      //         title: '打印表格',
      //         icon: 'dashboard',
      //         roles:"admin",
      //       },
            
      //     },
      //     {
      //       path: '/table/table3',
      //       //redirect: '/table/table4',
      //       name: 'table3',
      //       component: resolve => require(['@/views/table/table3'],resolve),
      //       meta:{
      //         title: '行内编辑表格',
      //         icon: 'dashboard',
      //         roles:"admin",
      //       },
            
      //     },
      //     {
      //       path: '/table/table4',
      //       name: 'table4',
      //       component: resolve => require(['@/views/table/table4'],resolve),
      //       meta:{
      //         title: '嵌套表格',
      //         icon: 'dashboard',
      //         roles:"admin",
      //       },
            
      //     },
      //     {
      //       path: '/table/table5',
      //       name: 'table5',
      //       component: resolve => require(['@/views/table/table5'],resolve),
      //       meta:{
      //         title: '树形表格',
      //         icon: 'dashboard',
      //         roles:"admin",
      //       },
            
      //     },
      //   ]
      // },
      // {
      //   path: '/form',
      //   name: 'form',
      //   component: resolve => require(['@/views/form/index'],resolve),
      //   meta:{
      //     title: '表单示例',
      //     icon: 'dashboard',
      //     roles:"super",
      //   },
      //   children:[
      //     {
      //       path: '/form/form1',
      //       name: 'form1',
      //       component: resolve => require(['@/views/form/form1'],resolve),
      //       meta:{
      //         title: '基础表单',
      //         icon: 'dashboard',
      //         roles:"super",
      //       },
           
      //     },
      //     {
      //       path: '/form/form2',
      //       name: 'form2',
      //       component: resolve => require(['@/views/form/form2'],resolve),
      //       meta:{
      //         title: '上传文件',
      //         icon: 'dashboard',
      //         roles:"super",
      //       },
           
      //     },
      //   ]
      // },   
      
    ]
  },
  { path: '/lockscreen', component: resolve => require(['@/views/err/lockscreen'],resolve),},
  { path: '*', component: resolve => require(['@/views/err/404'],resolve),}
  ];


const whiteList = ['/login','/Printing']// 白名单
router.beforeEach((to, from, next) => {
  if( sessionStorage.getItem('isLock')&&to.path!=='/lockscreen'){//如果有锁屏，就退出本次导航
    console.log(sessionStorage.getItem('isLock'),'导航守卫取值')
    next({path:'/lockscreen'})
    }else{
    let token=getCookie("token");
    if (token) { // 判断是否有token
      /* has token*/
      if (!store.getters.roles) { // 判断当前用户是否已拉取完user_info信息
        console.log(token,'token')
        console.log(JSON.parse(token),'aaa')
        let role= JSON.parse(token).role
        if(role){
          store.commit('SET_ROLES',role )
          store.dispatch('GenerateRoutes', role).then(() => { // 根据roles权限生成可访问的路由表
            router.addRoutes(store.getters.addRouters) // 动态添加可访问路由表
            next({ ...to, replace: true }) // hack方法 确保addRoutes已完成 ,设置replace:true，这样导航就不会留下历史记录
          })
        }else{
          console.log('获取role失败')
          store.dispatch('LogOut').then(() => {
            next({ path: '/login' })
          })
        }

      } else {
          next()
      }
      
    } else {

      /*没有token的话，判断是否在白名单中，是就进入，不是就跳转到登录页面*/
      if (whiteList.indexOf(to.path) !== -1) { // 在免登录白名单，直接进入
        next()
      } else {
        next(`/login?url=${to.path}`) // 否则全部重定向到登录页
      }
    }

    next()//锁屏结束
  }
})

router.afterEach((to) => {//from参数暂时用不到
  if (to.meta.title) {
    document.title = to.meta.title+' - '+ projectName
  }
})
export default router